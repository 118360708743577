<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-card>
        <v-card-text class="pa-8">
          <div class="d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="organization.logo_url || require('@/assets/images/logo.png')"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3"
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ organization.display_name }}
                </span>
              </div>
              <span class="d-block">{{ organization.name }} ({{ organization.roc_number }})</span>
              <span class="d-block">{{ organization.address }}</span>
              <span class="d-block">Tel: {{ organization.contact }}</span>
              <span class="d-block">{{ organization.website }}</span>
            </div>
            <!-- Right Content -->
            <div>
              <p class="font-weight-medium text-xl text--primary mb-4">
                Statement of Account
              </p>
              <table class="text-right ml-0 ml-sm-auto mr-0">
                <tr>
                  <td class="pe-2">
                    From:
                  </td>
                  <td class="text-left">
                    {{ customerStatement.start_date }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-2">
                    To:
                  </td>
                  <td class="text-left">
                    {{ customerStatement.end_date }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <v-card-text class="pa-8">
          <div class="d-block d-sm-flex">
            <div>
              <table class="w-full">
                <tr>
                  <th class="text-left pb-2">
                    Bill To:
                  </th>
                </tr>
                <template v-if="customer">
                  <tr>
                    <td>
                      {{ customer.name }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ customer.company }}
                    </td>
                  </tr>
                  <tr v-if="customer.address">
                    <td>
                      {{ customer.address }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ customer.contact_number }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ customer.email }}
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <v-spacer></v-spacer>
            <div>
              <table class="text-left text-sm-right ml-0 ml-sm-auto mt-5 mt-sm-0">
                <tr>
                  <td class="pe-4">
                    Invoiced:
                  </td>
                  <td class="text-right">
                    {{ utilsService.formatCurrency(customerStatement.total_invoice) }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-4 pb-2 total-divider">
                    Paid:
                  </td>
                  <td class="pb-2 text-right total-divider">
                    {{ utilsService.formatCurrency(customerStatement.total_paid) || '$0.00' }}
                  </td>
                </tr>
                <tr>
                  <td :class="$vuetify.breakpoint.smAndDown ? 'pe-4 pt-2' : 'pe-4 pt-2 text-no-wrap'">
                    Closing Balance:
                  </td>
                  <td class="text-left pt-2">
                    {{ utilsService.formatCurrency(customerStatement.closing_balance) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="d-none d-sm-block">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  DATE
                </th>
                <th>
                  ITEM
                </th>
                <th class="text-right pr-8">
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody v-if="customerStatement.data != 0">
              <tr
                v-for="item in customerStatement.data"
                :key="item.id"
              >
                <td style="vertical-align:top; padding-top: 14px;">
                  {{ item.invoice_date }}
                </td>
                <td>
                  <div class="my-3">
                    Invoice
                    <router-link
                      class="font-weight-medium text-decoration-none"
                      :to="{ name: 'invoice-view', params: { id: item.id } }"
                    >
                      #{{ item.invoice_number }}
                    </router-link>
                    <v-chip
                      v-if="item.amount_due > 0"
                      label
                      x-small
                      color="primary"
                      class="v-chip-light-bg error--text ma-1"
                    >
                      Overdue - ${{ item.amount_due }}
                    </v-chip>
                  </div>
                  <div v-if="item.receipts.length > 0">
                    <div
                      v-for="receipt in item.receipts"
                      :key="receipt.id"
                      class="mb-3"
                    >
                      Receipt
                      <router-link
                        class="font-weight-medium text-decoration-none"
                        :to="{ name: 'receipt-view', params: { id: receipt.id } }"
                      >
                        #{{ receipt.receipt_number }}
                      </router-link>
                      : Payment made on {{ receipt.receipt_date }}
                    </div>
                  </div>
                </td>
                <td class="text-right pr-8">
                  <div class="my-3">
                    {{ item.total }}
                  </div>
                  <div v-if="item.receipts.length > 0">
                    <div
                      v-for="receipt in item.receipts"
                      :key="receipt.id"
                      class="mb-3"
                    >
                      ({{ receipt.payment_amount }})
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="3">
                <div style="width: 500px; margin: 30px auto; font-size: 14px; text-align: center;">
                  <div style="margin-bottom: 10px;">
                    No record yet. Start by completing the form below
                  </div>
                  <div style="margin-bottom: 25px;">
                    I want to see Statement of Account for <a>{{ organization.display_name }}</a> between
                  </div>
                  <div class="d-flex">
                    <v-menu
                      v-model="startDateMenu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :append-icon="icons.mdiCalendar"
                          :value="startDateText"
                          label="From"
                          readonly
                          dense
                          outlined
                          hide-details
                          class="mb-5 mx-3"
                          style="width: 100px;"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateRange[0]"
                        color="primary"
                        @input="startDateMenu2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <!-- End Date filter -->
                    <v-menu
                      v-model="endDateMenu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :append-icon="icons.mdiCalendar"
                          :value="endDateText"
                          label="To"
                          readonly
                          dense
                          outlined
                          hide-details
                          class="mb-5 mr-3"
                          style="width: 100px;"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateRange[1]"
                        color="primary"
                        @input="endDateMenu2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-btn
                      color="primary"
                      @click="fetchCustomerStatement"
                    >
                      Go!
                    </v-btn>
                  </div>
                </div>
              </td>
            </tbody>
          </template>
        </v-simple-table>

        <!-- table view for mobile -->
        <v-data-table
          :headers="tableSmHeaders"
          :items="customerStatement.data"
          :expanded.sync="expanded"
          single-expand
          show-expand
          mobile-breakpoint="0"
          hide-default-footer
          class="fixed-action d-block d-sm-none"
        >
          <!-- Invoice Date -->
          <template #[`item.invoice_date`]="{item}">
            <div style="font-size: 12px;">
              {{ item.invoice_date }}
            </div>
          </template>

          <!-- Item -->
          <template #[`item.invoice_number`]="{item}">
            <div style="font-size: 12px;">
              Invoice
              <router-link
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'invoice-view', params: { id: item.id } }"
              >
                #{{ item.invoice_number }}
              </router-link>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="table-expand"
              :colspan="headers.length"
            >
              <div class="d-flex mt-2">
                <p>Invoice total:</p>
                <v-spacer></v-spacer>
                <p class="mr-1">
                  {{ item.total }}
                </p>
              </div>
              <div v-if="item.receipts.length > 0">
                <div
                  v-for="receipt in item.receipts"
                  :key="receipt.id"
                  class="d-flex"
                >
                  <p class="mr-1">
                    Receipt
                  </p>
                  <router-link
                    class="font-weight-medium text-decoration-none mr-1"
                    :to="{ name: 'receipt-view', params: { id: receipt.id } }"
                    style="font-size: 11px;"
                  >
                    #{{ receipt.receipt_number }}
                  </router-link>
                  <p>- {{ receipt.receipt_date }}</p>
                  <v-spacer></v-spacer>
                  <p>({{ receipt.payment_amount }})</p>
                </div>
              </div>
              <div class="text-right">
                <v-chip
                  v-if="item.amount_due > 0"
                  label
                  x-small
                  color="primary"
                  class="v-chip-light-bg error--text mb-3"
                >
                  Overdue - ${{ item.amount_due }}
                </v-chip>
              </div>
            </td>
          </template>
        </v-data-table>

        <v-divider v-if="customerStatement.data != 0"></v-divider>

        <v-card-text
          v-if="customerStatement.data != 0"
          class="pa-3 pa-sm-5"
        >
          <table class="ml-auto mr-3">
            <tr>
              <td class="pe-4">
                Closing Balance:
              </td>
              <td class="text-left">
                {{ utilsService.formatCurrency(customerStatement.closing_balance) }}
              </td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <!-- Start Date filter -->
          <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            :nudge-left="$vuetify.breakpoint.mdOnly ? 100 : 0"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="startDateText"
                label="From"
                readonly
                dense
                outlined
                hide-details
                class="mb-5"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange[0]"
              color="primary"
              @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
          <!-- End Date filter -->
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            :nudge-left="$vuetify.breakpoint.mdOnly ? 100 : 0"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="endDateText"
                label="To"
                readonly
                dense
                outlined
                hide-details
                class="mb-5"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange[1]"
              color="primary"
              @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-select
            v-model="invoiceType"
            :items="invoiceOptions"
            label="Type"
            single-line
            outlined
            dense
            hide-details
            class="mb-5"
          ></v-select>
          <v-btn
            color="primary"
            block
            @click="fetchCustomerStatement"
          >
            Filter
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-text>
          <v-btn
            class="mb-4"
            color="primary"
            block
            :loading="downloadStatementLoading"
            :disabled="downloadStatementLoading"
            @click="downloadStatement"
          >
            Download
          </v-btn>
          <v-btn
            color="secondary"
            block
            outlined
            :loading="reminderLoading"
            :disabled="reminderLoading"
            @click="remindStatement"
          >
            Send Via Email
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCalendar,
} from '@mdi/js'
import {
  ref,
  onMounted,
  inject,
  computed,
} from '@vue/composition-api'
import { saveAs } from 'file-saver'
import { useRouter } from '@core/utils'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route } = useRouter()
    const customerId = route.value.params.id
    const organizationData = computed(() => store.state.organization)

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const organization = ref({ ...organizationData.value })
    const customer = ref({})
    const customerStatement = ref([])

    const dateRange = ref([`${yearNow}-${monthNow}-01`, `${yearNow}-${monthNow}-${dayNow}`])
    const startDateMenu = ref(false)
    const endDateMenu = ref(false)
    const startDateMenu2 = ref(false)
    const endDateMenu2 = ref(false)
    const invoiceType = ref('All')
    const invoiceOptions = ref(['All', 'Outstanding', 'Partially Paid'])

    const downloadStatementLoading = ref(false)
    const reminderLoading = ref(false)

    // Table Handlers
    const expanded = ref([])
    const tableSmHeaders = computed(() => (
      [
        { text: 'DATE', value: 'invoice_date' },
        { text: 'ITEM', value: 'invoice_number' },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Computed
    const startDateText = computed(() => utilsService.formatDate(dateRange.value[0]))
    const endDateText = computed(() => utilsService.formatDate(dateRange.value[1]))

    // Methods
    const fetchCustomer = () => {
      store
        .dispatch('clericalSettingsStore/fetchCustomer', { id: customerId })
        .then(response => {
          customer.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customer. Please refresh!')
        })
    }
    const fetchCustomerStatement = () => {
      store
        .dispatch('clericalSettingsStore/fetchCustomerStatement', {
          id: customerId,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1],
          status: invoiceType.value,
        })
        .then(response => {
          customerStatement.value = response.data
          customerStatement.value.start_date = startDateText.value
          customerStatement.value.end_date = endDateText.value
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customer statement. Please refresh!')
        })
    }
    const downloadStatement = () => {
      downloadStatementLoading.value = true
      store
        .dispatch('clericalSettingsStore/downloadStatement', {
          id: customerId,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1],
          status: invoiceType.value,
        })
        .then(response => {
          saveAs(response, `Statement-${startDateText.value}-${endDateText.value}.pdf`)
          downloadStatementLoading.value = false
        })
        .catch(error => {
          downloadStatementLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading customer statement. Please refresh!')
        })
    }
    const remindStatement = () => {
      reminderLoading.value = true
      store
        .dispatch('clericalSettingsStore/remindStatement', {
          id: customerId,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1],
          status: invoiceType.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          reminderLoading.value = false
        })
        .catch(error => {
          reminderLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending statement via email. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchCustomer()
      fetchCustomerStatement()
    })

    return {
      // Initializers
      utilsService,

      // Computed
      startDateText,
      endDateText,

      // Properties
      organization,
      customer,
      customerStatement,

      dateRange,
      startDateMenu,
      endDateMenu,
      startDateMenu2,
      endDateMenu2,
      invoiceType,
      invoiceOptions,

      downloadStatementLoading,
      reminderLoading,

      // Table Handlers
      expanded,
      tableSmHeaders,

      // Icons
      icons: {
        mdiCalendar,
      },

      // Methods
      fetchCustomerStatement,
      downloadStatement,
      remindStatement,
    }
  },
}
</script>

<style scoped>
.total-divider {
  border-bottom: 0.5px solid rgba(94, 86, 105, 0.14);
}
</style>
